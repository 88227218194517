<template>
  <div>
    <el-card class="box-card card-contanier">
      <div class="card-content">
        <el-form :inline="true" @submit.native.prevent>
          <div style="float: left; width: 94%">
            <el-form-item label="分组名称：">
              <el-input
                v-model="searchForm.sgName"
                placeholder="请输入分组名称"
                size="medium"
                clearable
                @keyup.enter.native="search"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="search" icon="el-icon-search" size="medium"
                >搜索</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button @click="refresh" icon="el-icon-refresh" size="medium"
                >重置</el-button
              >
            </el-form-item>
          </div>

          <el-form-item>
            <el-button type="primary" @click="addStoreGroup" size="medium" v-if="hasAuth('sy:storegroup:add')"
              >新增</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      v-loading="isLoading"
      border
      stripe
      size="small"
      style="width: 100%; margin-top: 15px"
    >
      <el-table-column
        type="index"
        label="序号"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="sgName"
        label="分组名称"
        width="180"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="sgCreate" label="分组时间" align="center">
        <template slot-scope="scope">
          {{
            scope.row.sgCreate ? scope.row.sgCreate.replaceAll("T", " ") : ""
          }}
        </template>
      </el-table-column>
      <el-table-column prop="sgUpdate" label="修改时间" align="center">
        <template slot-scope="scope">
          {{
            scope.row.sgUpdate ? scope.row.sgUpdate.replaceAll("T", " ") : ""
          }}
        </template>
      </el-table-column>
      <el-table-column prop="meName" label="所属商户" align="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="soeCount" label="门店数量" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="editStore(scope.row)" v-if="hasAuth('sy:storegroup:edit')"
            >编辑分组</el-button
          >
          <el-divider direction="vertical"></el-divider>
          <el-popconfirm
            title="确认删除删除分组？"
            icon="el-icon-info"
            icon-color="red"
            @confirm="deleteStore(scope.row)"
             v-if="hasAuth('sy:stroegroup:delete')"
          >
            <el-button type="text" slot="reference">删除分组</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 50, 100]"
      :current-page="current"
      :page-size="size"
      :total="total"
      style="float: right"
    >
    </el-pagination>

    <el-dialog
      :title="isAdd ? '新增分组' : '修改-' + titleName + '分组'"
      :visible.sync="addDialogVisible"
      width="50%"
      :close-on-click-modal="false"
    >
      <div v-loading="dialogLoading">
        <el-form
          :model="ruleForm"
          :rules="ruleFormRules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="分组名称：" prop="sgName" label-width="100px">
            <el-input
              v-model="ruleForm.sgName"
              placeholder="请输入门店名称"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="选择门店：" prop="soeIdList" label-width="100px">
            <el-transfer
              v-model="ruleForm.soeIdList"
              :data="storeList"
              :props="{ key: 'soeId', label: 'soeName' }"
              :titles="['未分组门店', '已分组门店']"
            ></el-transfer>
          </el-form-item>
        </el-form>
        <div
          slot="footer"
          class="dialog-footer"
          style="text-align: right; margin-top: 20px"
        >
          <el-button type="primary" @click="onSubmit()">保存</el-button>
          <el-button
            @click="
              () => {
                this.addDialogVisible = false;
              }
            "
            >取消</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import store from "../../api/stroe";
export default {
  name: "AssetType",
  data() {
    return {
      searchForm: {
        sgName: "",
      },
      total: 0,
      size: 10,
      current: 1,
      isLoading: true,
      tableData: [],

      addDialogVisible: false,
      dialogLoading: false,

      isAdd: true,
      titleName: "",
      ruleForm: {
        sgName: "",
        soeIdList: [],
        sgId: "", //修改分组用
      },
      storeList: [],
      ruleFormRules: {
        sgName: [
          { required: true, message: "请输入分组名称", trigger: "blur" },
        ],
        soeIdList: [
          { required: true, message: "请至少选择一个门店", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getStoreGroupList();
    this.getAllStoreInfo();
  },
  methods: {
    getStoreGroupList() {
      this.isLoading = true;
      let params = {
        sgName: this.searchForm.sgName,
        current: this.current,
        size: this.size,
      };
      store.getStoreGroupList(params).then((res) => {
        this.tableData = res.data.data.result.records;
        this.size = res.data.data.result.size;
        this.current = res.data.data.result.current;
        this.total = res.data.data.result.total;
        this.isLoading = false;
      });
    },
    search() {
      this.current = 1;
      this.getStoreGroupList();
    },
    refresh() {
      this.searchForm = { sgName: "" };
      this.current = 1;
      this.getStoreGroupList();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val;
      this.getUserList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.current = val;
      this.getStoreGroupList();
    },
    getAllStoreInfo(sgId) {
      store
        .getAllStoreInfo({
          params: {
            sgId: sgId,
          },
        })
        .then((res) => {
          this.storeList = res.data.data.result;
        });
    },
    addStoreGroup() {
      this.resetForm("ruleForm");
      this.getAllStoreInfo();
      this.isAdd = true;
      this.addDialogVisible = true;
    },
    resetForm(formName) {
      if (this.$refs[formName] != null && this.$refs[formName] !== undefined) {
        this.$refs[formName].resetFields();
      }
      this.ruleForm = {
        sgName: "",
        soeIdList: [],
        sgId: "", //修改时使用
      };
    },
    onSubmit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.dialogLoading = true;
          store
            .changeStoreGroupInfo(this.isAdd ? "add" : "edit", this.ruleForm)
            .then((res) => {
              if (res.data.code === 200) {
                this.$notify({
                  title: "操作成功",
                  message: "",
                  position: "bottom-right",
                  type: "success",
                });
                this.dialogLoading = false;
                this.addDialogVisible = false;
                this.getStoreGroupList();
              }
            })
            .catch((e) => {
              this.dialogLoading = false;
            });
        }
      });
    },
    editStore(row) {
      console.log(row);
      this.isAdd = false;
      this.ruleForm.sgId = row.sgId;
      this.isLoading = true;
      this.getAllStoreInfo(row.sgId);
      store.getGroupInfoById(row.sgId).then((res) => {
        let storeGroupInfo = res.data.data.result;
        this.ruleForm.sgName = storeGroupInfo.sgName;
        this.ruleForm.soeIdList = storeGroupInfo.soeIdList;
        this.isLoading = false;
        this.addDialogVisible = true;
        this.titleName = storeGroupInfo.sgName;
      });
    },
    deleteStore(row) {
      this.$confirm(
        "删除后，原该分组下的门店可重新选择分组，是否确认删除该门店分组？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          store.deleteStoreGroupById(row.sgId).then((res) => {
            if (res.data.code === 200) {
              this.$notify({
                title: "prefect",
                message: "操作成功",
                type: "success",
                position: "bottom-right",
              });
            } else {
              this.$notify({
                title: "prefect",
                message: "操作失败",
                type: "error",
                position: "bottom-right",
              });
            }
            this.search();
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
</style>
